import { useState, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import Image from 'next/legacy/image'
import isEmail from 'validator/lib/isEmail'
import PropTypes from 'prop-types'

import Link from '../Link'
import Text from '../Text'

import styles from './style.module.css'

import { subscribeNewsletterMutation } from '@gql/userMutation'
import {
  aboutBaseLink,
  contactUsLinks,
  customerSupportLinks, findUsLinks,
  joinBaseLinks,
  socialMediaLinks,
} from '@constants/footerLinks'
import TextInput from "@components/TextInput"
import BoxButton from "@components/BoxButton"
import { useTranslation } from "next-i18next"

function Footer({ className }) {
  const [subscribe] = useMutation(subscribeNewsletterMutation)

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const onChangeEmail = useCallback(e => {
    if (e.which == 13 || e.keyCode == 13) {
      e.preventDefault()
      return false
    }

    setEmail(e.target.value)
    setErrorMessage('')
  }, [])

  const submitEmail = useCallback(
    async e => {
      e.preventDefault()

      if (isEmail(email)) {
        setLoading(true)

        try {
          const {
            data: { subscribeNewsletter: result },
          } = await subscribe({ variables: { email } })

          setLoading(false)
          setSuccess(result)
          setEmail('')
          setErrorMessage('')

          setTimeout(() => setSuccess(false), 2500)
        } catch (e) {
          setErrorMessage(
            'Something happened with the server, please try again..'
          )
          setLoading(false)
        }
      } else {
        setErrorMessage('Hmm, that email isn’t complete. Please check again')
      }

      return false
    },
    [email, subscribe]
  )

  const { t } = useTranslation("common")

  return (
    <div className={`${styles.container} ${styles.black} ${className}`}>
      <div className={styles.newsletterContainer}>
        <Text variant="smallSubtitle">{t("common:footer.newsletterTitle")}</Text>
        <div className={styles.newsletterInput}>
          <form onSubmit={submitEmail} className={styles.formContainer}>
            <TextInput
              variant="primaryBorder"
              containerClassName={styles.emailInput}
              onChange={onChangeEmail}
              placeholder={t("common:footer.emailPlaceholder")}
              value={email}
              disabled={loading}
            />
            <BoxButton type="submit">
              {t("common:footer.cta-newsletter")}
            </BoxButton>
          </form>
          {success && (
            <Text variant="smallDescription" className={styles.success}>
              Thanks for joining the Base community!
            </Text>
          )}
          {errorMessage && (
            <Text variant="smallDescription" className={styles.error}>
              {errorMessage}
            </Text>
          )}
        </div>
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.content}>
          <div className={styles.logo}>
            <Link link="/" className={styles.logoImage}>
            <span>
              <Image
                alt="base skincare"
                src="https://static.base.co.id/logo-black-rev.png"
                layout="fixed"
                height={36}
                width={64}
              />
            </span>
            </Link>
            <Text variant="descriptionBold" className={styles.title}>PT. Basis Inovasi Global</Text>
            <Link externalLink="https://maps.app.goo.gl/GzMVyKULPYars27t9" target="_blank"
                  className={styles.addressContainer}>
              <Text variant="descriptionBold" className={styles.officeTitle}>BASE Headquarters</Text>
              <Text variant="smallDescription" className={styles.officeAddress}>The Darmawangsa Square, <br/>Jl.
                Darmawangsa
                VI
                No.6, Pulo, Kec.
                Kby. Baru, Daerah Khusus Ibukota Jakarta 12160</Text>
            </Link>
          </div>

          <div className={styles.linkContainer}>
            <div>
              <Text variant='descriptionBold'
                    className={styles.linkTitle}>{t("common:footer.link.about-base.title")}</Text>
              {aboutBaseLink(t).map((data, index) => (
                <Text
                  key={`footer_link_${index}`}
                  variant="smallDescription"
                  color="black"
                  className={`${styles.link} ${data.hideOnMobile && styles.hide}`}
                >
                  <Link
                    link={data.link}
                    externalLink={data.externalLink}
                    target="_blank"
                    className={styles.hoverLink}
                  >
                    {data.text}
                  </Link>
                </Text>
              ))}
            </div>
            <div>
              <Text variant='descriptionBold'
                    className={styles.linkTitle}>{t("common:footer.link.customer-support.title")}</Text>
              <div className={styles.sublinkContainer}>
                {customerSupportLinks(t).map((data, index) => (
                  <Text
                    key={`footer_link_${index}`}
                    variant="smallDescription"
                    color="black"
                    className={`${styles.link} ${data.hideOnMobile && styles.hide}`}
                  >
                    <Link
                      link={data.link}
                      externalLink={data.externalLink}
                      target="_blank"
                      className={styles.hoverLink}
                    >
                      {data.text}
                    </Link>
                  </Text>
                ))}
              </div>

              <Text variant='descriptionBold'
                    className={styles.joinBaseTitle}>{t("common:footer.link.join-base.title")}</Text>
              {joinBaseLinks(t).map((data, index) => (
                <Text
                  key={`footer_link_${index}`}
                  variant="smallDescription"
                  color="black"
                  className={`${styles.link} ${data.hideOnMobile && styles.hide}`}
                >
                  <Link
                    link={data.link}
                    externalLink={data.externalLink}
                    target="_blank"
                    className={styles.hoverLink}
                  >
                    {data.text}
                  </Link>
                </Text>
              ))}
            </div>
            <div className={styles.contactUsContainer}>
              <Text variant='descriptionBold'
                    className={styles.linkTitle}>{t("common:footer.link.contact-us.title")}</Text>
              <div className={styles.contactUsLinkContainer}>
                {contactUsLinks.map((data, index) => (
                  <div key={index}>
                    <Text variant='smallDescriptionBold'>{data.title}</Text>
                    <Text
                      key={`footer_link_${index}`}
                      variant="smallDescription"
                      color="black"
                      className={`${styles.link} ${data.hideOnMobile && styles.hide}`}
                    >
                      <Link
                        link={data.link}
                        externalLink={data.externalLink}
                        target="_blank"
                        className={styles.hoverLink}
                      >
                        {data.caption}
                      </Link>
                    </Text>
                  </div>
                ))}
              </div>
              <Text variant='descriptionBold'
                    className={styles.linkSocialTitle}>{t("common:footer.link.social-media.title")}</Text>
              <div className={styles.iconSocialContainer}>
                {socialMediaLinks.map((data, index) => (
                  <Link
                    key={`footer_link_${index}`}
                    link={data.link}
                    externalLink={data.externalLink}
                    target="_blank"
                    className={styles.icon}
                  >
                    <Image src={data.image} alt={data.caption} width={24} height={24}/>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.findUsContainer}>
          <Text variant='descriptionBold'>{t("common:footer.link.find-us.title")}</Text>
          <div className={styles.ecommerceLogoContainer}>
            {findUsLinks.map((data, index) => (
              <Link
                key={`footer_link_${index}`}
                link={data.link}
                externalLink={data.externalLink}
                target="_blank"
              >
                <Image src={data.image} quality={100} alt={data.caption} width={120} height={40}/>
              </Link>
            ))}
          </div>
        </div>
        <div className={styles.copyrightContainer}>
          <Text variant="smallDescription" className={styles.companyName}>
            © {new Date().getFullYear()}, PT Basis Inovasi Global
          </Text>
        </div>
      </div>
    </div>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
}

Footer.defaultProps = {
  textColor: 'black',
}

export default Footer
