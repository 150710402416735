import { useState, useCallback, forwardRef, memo } from 'react'
import Image from 'next/legacy/image'
import PropTypes from 'prop-types'

import styles from './style.module.css'

const TextInput = forwardRef(
  (
    {
      type,
      variant,
      placeholder,
      containerClassName,
      errorMessage,
      autoComplete,
      autoFocus,
      disabled,
      showClearButton,
      inputIcon,
      rightIconUrl,
      onClickClearButton,
      onFocus,
      onBlur,
      onChange,
      onKeyUp,
      onKeyPress,
      onKeyDown,
      value,
      max,
      maxLength,
      isValid,
      isHidePassword,
      onClickHidePassword,
    },
    ref
  ) => {
    const isTextArea = type === 'textarea'
    const [inputFocus, setInputFocus] = useState(autoFocus)

    const focusHandler = useCallback(() => {
      setInputFocus(true)
      if (onFocus) onFocus()
    }, [onFocus])

    const blurHandler = useCallback(() => {
      setInputFocus(false)
      if (onBlur) onBlur()
    }, [onBlur])

    let InputIcon = ''

    switch (inputIcon) {
      case 'search':
        InputIcon = (
          <Image
            alt="search"
            src="https://static.base.co.id/search.svg"
            layout="fixed"
            height={18}
            width={18}
          />
        )
        break
    }

    return (
      <div className={`${styles.container} ${containerClassName}`}>
        <div
          className={`
          ${styles.inputContainer}
          ${styles[variant]}
          ${isTextArea && styles.textAreaContainer}
          ${inputFocus && styles.inputFocus}
          ${errorMessage && styles.inputError}
          ${disabled && styles.inputDisabled}
        `}
        >
          {inputIcon && !isTextArea && (
            <span className={styles.inputIcon}>{InputIcon}</span>
          )}
          {isTextArea ? (
            <textarea
              ref={ref}
              placeholder={placeholder}
              value={value}
              autoFocus={autoFocus}
              onChange={onChange}
              onKeyUp={onKeyUp}
              onKeyPress={onKeyPress}
              onKeyDown={onKeyDown}
              minLength={1}
              maxLength={maxLength}
              className={`
                  ${styles.textarea}
                  ${styles[variant]}
                  ${InputIcon && styles.hasIcon}
                `}
              onFocus={focusHandler}
              onBlur={blurHandler}
            />
          ) : (
            <input
              ref={ref}
              type={type}
              placeholder={placeholder}
              value={value}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              disabled={disabled}
              onChange={onChange}
              onKeyUp={onKeyUp}
              onKeyPress={onKeyPress}
              onKeyDown={onKeyDown}
              min={1}
              max={max}
              className={`
                ${styles.input}
                ${styles[variant]}
                ${InputIcon && styles.hasIcon}
              `}
              onFocus={focusHandler}
              onBlur={blurHandler}
            />
          )}
          {isValid && (type === 'email' || type === 'tel') && (
            <span className={styles.commonIcon}>
              <Image
                alt="error icon"
                src="https://static.base.co.id/valid-icon-green.svg"
                layout="fixed"
                height={20}
                width={20}
              />
            </span>
          )}
          {errorMessage && (type === 'email' || type === 'tel') && (
            <span className={styles.commonIcon}>
              <Image
                alt="error icon"
                src="https://static.base.co.id/error-icon-red.svg"
                layout="fixed"
                height={20}
                width={20}
              />
            </span>
          )}
          {showClearButton && !rightIconUrl && (
            <span className={styles.clearIcon} onClick={onClickClearButton}>
              <Image
                alt="clear input"
                src="https://static.base.co.id/clear-grey.svg"
                layout="fixed"
                height={18}
                width={18}
              />
            </span>
          )}
          {type === 'password' && isHidePassword && (
            <span className={styles.commonIcon} onClick={onClickHidePassword}>
              <Image
                alt="input icon"
                src={"https://static.base.co.id/eye-closed.svg"}
                layout="fill"
                objectFit="contain"
              />
            </span>
          )}
          {type === 'textPassword' && !isHidePassword && (
            <span className={styles.commonIcon} onClick={onClickHidePassword}>
              <Image
                alt="input icon"
                src={"https://static.base.co.id/eye-open.svg"}
                layout="fill"
                objectFit="contain"
              />
            </span>
          )}
          {!showClearButton && rightIconUrl && (
            <span className={styles.rightIcon} onClick={onClickClearButton}>
              <Image
                alt="input icon"
                src={rightIconUrl}
                layout="fill"
                objectFit="contain"
              />
            </span>
          )}
        </div>
        {errorMessage && <div className={styles.errorText}>{errorMessage}</div>}
      </div>
    )
  }
)

/**
 * @typedef {React.ForwardRefExoticComponent<TextInput.propTypes & React.RefAttributes<HTMLElement>>} TextInputComponent
 */

TextInput.displayName = 'TextInput'

TextInput.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  containerClassName: PropTypes.string,
  errorMessage: PropTypes.string,
  rightIconUrl: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  showClearButton: PropTypes.bool,
  onClickClearButton: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  inputIcon: PropTypes.oneOf(['search', undefined]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  isValid: PropTypes.bool,
  isHidePassword: PropTypes.bool,
  onClickHidePassword: PropTypes.func,
}

TextInput.defaultProps = {
  type: 'text',
}

/**
 * @type {React.MemoExoticComponent<TextInputComponent>}
 */
export default memo(TextInput)
